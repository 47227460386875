import classnames from "classnames";
import React, { DetailedHTMLProps, ButtonHTMLAttributes } from "react";

export type ButtonProps = {
  color?: "primary" | "secondary" | "link" | "danger";
  size?: "small" | "normal";
  children?: React.ReactNode;
} & DetailedHTMLProps<
  ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export function Button(props: ButtonProps) {
  const {
    type = "button",
    size,
    color = "secondary",
    disabled,
    children,
    className,
    ...rest
  } = props;

  function getColor() {
    switch (color) {
      case "primary": {
        return [
          "bg-sky-700",
          "text-white",
          ...(disabled ? [] : ["hover:bg-sky-800"]),
        ].join(" ");
      }
      case "danger": {
        return [
          "bg-rose-700",
          "text-white",
          ...(disabled ? [] : ["hover:bg-rose-800"]),
        ].join(" ");
      }
      case "link": {
        return [
          "text-sky-800",
          "underline",
          ...(disabled ? [] : ["hover:text-sky-900"]),
        ].join(" ");
      }
      default: {
        return [
          "bg-white",
          "text-sky-900",
          "border-slate-400",
          "border",
          ...(disabled ? [] : ["hover:bg-slate-50"]),
        ].join(" ");
      }
    }
  }

  const getSize = () => {
    switch (size) {
      case "small": {
        return "py-1 px-2 text-sm";
      }
      default: {
        return "py-2 px-3";
      }
    }
  };

  const rootStyle = classnames(
    "flex self-baseline items-center justify-center gap-1 font-semibold rounded whitespace-nowrap transition-colors",
    getSize(),
    getColor(),
    {
      "opacity-50 cursor-not-allowed": Boolean(disabled),
      [`${className}`]: Boolean(className),
    }
  );

  return (
    <button className={rootStyle} type={type} disabled={disabled} {...rest}>
      {children}
    </button>
  );
}
