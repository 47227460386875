import { StrictMode, Suspense } from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import { App } from "@/app";
import { QueryProvider } from "@/shared/providers/QueryProvider";
import "@/shared/services/I18nService";

import "./main.css";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <BrowserRouter>
      <QueryProvider>
        <Suspense fallback="Loading...">
          <App />
        </Suspense>
      </QueryProvider>
    </BrowserRouter>
  </StrictMode>
);
