import { yupResolver } from "@hookform/resolvers/yup";
import { stringifyUrl } from "query-string";
import { useMemo } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

import {
  Form,
  Input,
  Button,
  Label,
  FormGroup,
  ValidationMessage,
} from "@/shared/components";
import { useValidation } from "@/shared/hooks";
import { ErrorService, UrlService } from "@/shared/services";

const { getURLWithVersion } = UrlService;

type SignInForm = {
  organizationName: string;
};

export function SignIn() {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();

  const { yup } = useValidation();

  const code = searchParams.get("code");

  const errorMessageKey = ErrorService.extractAPIAuthError(code);

  const schema = useMemo(
    () =>
      yup.object({
        organizationName: yup.string().trim().required(),
      }),
    [yup]
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm<SignInForm>({
    mode: "all",
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<SignInForm> = ({ organizationName }) => {
    window.location.href = stringifyUrl({
      url: getURLWithVersion("/login/org"),
      query: {
        organizationName,
        ...(import.meta.env.APP_AUTH_REDIRECT_ORIGIN_URL && {
          origin: import.meta.env.APP_AUTH_REDIRECT_ORIGIN_URL,
        }),
        ...(code && { re: true }),
      },
    });
  };

  return (
    <div className="flex flex-col flex-grow items-center pt-32">
      <Form
        className="w-full p-16 rounded-lg bg-white shadow-lg"
        onSubmit={handleSubmit(onSubmit)}
      >
        <img src="/assets/images/logo_h.svg" alt="logo" className="h-32" />
        <FormGroup>
          <Label>{t("signIn.organizationName")}</Label>
          <Input
            label="organizationName"
            register={register}
            error={errors.organizationName}
          />
          {!isDirty && errorMessageKey && (
            <ValidationMessage
              error={{ type: "custom", message: t(errorMessageKey) }}
            />
          )}
        </FormGroup>
        <div className="flex justify-center">
          <Button
            color="primary"
            type="submit"
            className="w-40"
            disabled={!isValid}
          >
            {t("signIn.signIn")}
          </Button>
        </div>
      </Form>
    </div>
  );
}
