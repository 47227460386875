import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Page, PageTitle } from "@/shared/components";
import { useUpdateEmailCompleteMutation } from "@/shared/hooks";

export function AccountEmailComplete() {
  const { t } = useTranslation();
  const { token } = useParams<{ token: string }>();

  const [updateEmailComplete] = useUpdateEmailCompleteMutation();

  const [error, setError] = useState(false);

  useEffect(() => {
    if (token) {
      updateEmailComplete({ token })
        .unwrap()
        .then(() => {
          toast.success(t("message.emailUpdated"));
          window.location.replace("/");
        })
        .catch(() => {
          setError(true);
        });
    } else {
      setError(true);
    }
  }, [updateEmailComplete, token]);

  return (
    <Page>
      <PageTitle>{t("accountEmail.title")}</PageTitle>
      <p>{t("accountEmail.updating")}</p>
      {error && <p role="alert">{t("message.emailUpdateError")}</p>}
    </Page>
  );
}
