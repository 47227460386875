import classnames from "classnames";
import { NavLink } from "react-router-dom";

export type MenuItemProps = { to: string; children: React.ReactNode };

export function MenuItem(props: MenuItemProps) {
  const { children, to } = props;

  return (
    <li>
      <NavLink
        to={to}
        className={({ isActive }) =>
          classnames("flex items-center gap-2 py-2 px-4", {
            "text-slate-400": !isActive,
            "text-slate-100 bg-slate-900": isActive,
          })
        }
      >
        {children}
      </NavLink>
    </li>
  );
}
