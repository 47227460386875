import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Page, PageTitle } from "@/shared/components";
import { useUpdateUserEmailComplete } from "@/shared/hooks";

export function AccountEmailComplete() {
  const { t } = useTranslation();
  const { token } = useParams<{ token: string }>();

  const { mutateAsync: updateEmailComplete } = useUpdateUserEmailComplete();

  const [hasError, setError] = useState(false);

  useEffect(() => {
    if (token) {
      const execute = async () => {
        try {
          await updateEmailComplete({ token });
          toast.success(t("message.emailUpdated"));
          window.location.replace("/");
        } catch (error) {
          setError(true);
        }
      };

      execute();
    } else {
      setError(true);
    }
  }, [updateEmailComplete, token]);

  return (
    <Page>
      <PageTitle>{t("accountEmail.title")}</PageTitle>
      <p>{t("accountEmail.updating")}</p>
      {hasError && <p role="alert">{t("message.emailUpdateError")}</p>}
    </Page>
  );
}
