import { yupResolver } from "@hookform/resolvers/yup";
import { useMemo } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import {
  Label,
  Input,
  Page,
  PageTitle,
  Form,
  FormGroup,
  Button,
  Icon,
  IconTypeSolid,
} from "@/shared/components";
import { useValidation, useUpdateUserEmail, useUser } from "@/shared/hooks";
import { UserService, ErrorService } from "@/shared/services";

type AccountEmailForm = {
  newEmail: string;
};

export function AccountEmail() {
  const { t } = useTranslation();
  const { yup } = useValidation();

  const { data: user } = useUser();

  const { mutateAsync: updateEmail, isPending: submitting } =
    useUpdateUserEmail();

  const schema = useMemo(
    () =>
      yup.object({
        newEmail: yup.string().required().email().max(255),
      }),
    [yup]
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<AccountEmailForm>({
    mode: "all",
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<AccountEmailForm> = async (data, e) => {
    e?.preventDefault();

    try {
      await updateEmail(data);
      toast.success(t("message.emailUpdateRequested"));
    } catch (error) {
      toast.error(ErrorService.parseAPIErrorMessage(error));
    }
  };

  return (
    <Page>
      <PageTitle>{t("accountEmail.title")}</PageTitle>
      {UserService.hasSSOIntegration(user) && (
        <div className="bg-white border border-yellow-500 p-4 rounded flex items-center gap-4 whitespace-pre">
          <Icon
            icon={IconTypeSolid.faWarning}
            size="2x"
            className="text-yellow-500"
          />
          {t("accountEmail.ssoIntegrated")}
        </div>
      )}
      <p>{t("accountEmail.description")}</p>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Label htmlFor="email">{t("accountEmail.newEmail")}</Label>
          <Input
            id="email"
            type="email"
            label="newEmail"
            register={register}
            error={errors.newEmail}
          />
        </FormGroup>
        <FormGroup>
          <div>
            <Button type="submit" color="primary" disabled={submitting}>
              <Icon icon={IconTypeSolid.faEnvelope} />
              {t("accountEmail.send")}
            </Button>
          </div>
        </FormGroup>
      </Form>
    </Page>
  );
}
