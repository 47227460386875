import classnames from "classnames";
import { useTranslation } from "react-i18next";

export type FooterProps = {
  className?: string;
};

export function Footer(props: FooterProps) {
  const { className } = props;
  const { t } = useTranslation();

  const rootStyle = classnames(
    "flex justify-between items-center gap-8 h-16 px-8",
    {
      [`${className}`]: Boolean(className),
    }
  );

  return (
    <footer className={rootStyle}>
      <div className="flex gap-4">
        <a href="https://givery.co.jp" target="_blank" rel="noreferrer">
          {t("footer.aboutUs")}
        </a>
        <a href={t("footer.privacyUrl")} target="_blank" rel="noreferrer">
          {t("footer.privacy")}
        </a>
        <a href="https://tracks.run/terms" target="_blank" rel="noreferrer">
          {t("footer.terms")}
        </a>
      </div>
      <div>
        <p>{t("footer.copyright")}</p>
      </div>
    </footer>
  );
}
