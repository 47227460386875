import Bowser from "bowser";

const browser = Bowser.getParser(window.navigator.userAgent);

const isValidBrowser = browser.satisfies({
  chrome: ">=103",
  firefox: ">=100",
  edge: ">=103",
  safari: ">=15",
});

export { isValidBrowser };
