import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ValidationService } from "@/shared/services";

const { yup } = ValidationService;

export function useValidation() {
  const { t } = useTranslation();

  useMemo(() => {
    yup.setLocale({
      mixed: {
        required: t("validation.required"),
      },
      string: {
        min: t("validation.string.min"),
        max: t("validation.string.max"),
        email: t("validation.string.email"),
      },
    });
  }, [yup]);

  return { yup };
}
