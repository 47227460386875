import classnames from "classnames";
import { DetailedHTMLProps, HTMLAttributes } from "react";
import { FieldError } from "react-hook-form";

export type ValidationMessageProps = {
  error: FieldError;
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export function ValidationMessage(props: ValidationMessageProps) {
  const { error, className } = props;

  const rootStyle = classnames("text-rose-500 text-xs italic", {
    [`${className}`]: Boolean(className),
  });

  return (
    <p className={rootStyle} role="alert">
      {error.message}
    </p>
  );
}
