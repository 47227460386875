import { Outlet } from "react-router-dom";

import { Header } from "@/app";
import { Menu } from "@/shared/components";

export function AccountContainer() {
  return (
    <div className="flex flex-grow">
      <Menu />
      <main className="flex flex-col flex-grow bg-white overflow-auto">
        <Header />
        <section className="flex flex-col flex-grow p-8 bg-slate-100">
          <Outlet />
        </section>
      </main>
    </div>
  );
}
