import {
  createApi,
  fetchBaseQuery,
  FetchBaseQueryError,
} from "@reduxjs/toolkit/query/react";

import {
  UserResponse,
  UserMutation,
  EmailMutation,
  EmailCompleteMutation,
  APIResponse,
  ImageUploadRequestPayload,
  ImageUploadConfigResponse,
  ImageUploadSignatureResponse,
  ImageUploadResponse,
} from "@/shared/models";
import { UrlService } from "@/shared/services";

const { getURLWithVersion } = UrlService;

const transformResponse = <T>(response: APIResponse<T>): T => response.result;

export const apiSlice = createApi({
  baseQuery: fetchBaseQuery({
    credentials: "include",
  }),
  tagTypes: ["User", "UserMFA"],
  endpoints: (builder) => ({
    getUser: builder.query<UserResponse | undefined, void>({
      async queryFn(arg, api, extraOptions, fetchWithBQ) {
        const result = await fetchWithBQ(getURLWithVersion("/api/user"));
        if (result.error) {
          return result.error.status === 401
            ? { data: undefined }
            : { error: result.error as FetchBaseQueryError };
        }
        return { data: (result.data as APIResponse<UserResponse>).result };
      },
      providesTags: ["User"],
    }),
    updateUser: builder.mutation<UserResponse, UserMutation>({
      query: (body) => ({
        url: getURLWithVersion("/api/user"),
        method: "put",
        body,
      }),
      invalidatesTags: ["User"],
    }),
    updatePassword: builder.mutation<void, void>({
      query: () => ({
        url: getURLWithVersion("/api/user/password"),
        method: "post",
      }),
    }),
    updateEmail: builder.mutation<void, EmailMutation>({
      query: (body) => ({
        url: getURLWithVersion("/api/user/email"),
        method: "post",
        body,
      }),
    }),
    updateEmailComplete: builder.mutation<void, EmailCompleteMutation>({
      query: (body) => ({
        url: getURLWithVersion("/api/user/email"),
        method: "put",
        body,
      }),
      invalidatesTags: ["User"],
    }),
    getUserMFA: builder.query<{ enabled: boolean }, void>({
      query: () => ({
        url: getURLWithVersion("/api/user/mfa"),
      }),
      transformResponse,
      providesTags: ["UserMFA"],
    }),
    resetUserMFA: builder.mutation<void, void>({
      query: () => ({
        url: getURLWithVersion("/api/user/mfa"),
        method: "delete",
      }),
      invalidatesTags: ["UserMFA"],
    }),
    uploadImage: builder.mutation<
      ImageUploadResponse,
      ImageUploadRequestPayload
    >({
      async queryFn(arg, api, extraOptions, fetchWithBQ) {
        const { width, height, tags, transformation, file } = arg;

        const configResult = await fetchWithBQ(
          getURLWithVersion("/api/images/config")
        );

        if (configResult.error) {
          return {
            error: configResult.error as FetchBaseQueryError,
          };
        }

        const {
          result: { cloud_name: cloudName },
        } = configResult.data as APIResponse<ImageUploadConfigResponse>;

        const signatureResult = await fetchWithBQ({
          url: getURLWithVersion("/api/images/signature"),
          params: { width, height, tags, transformation },
        });

        if (signatureResult.error) {
          return {
            error: signatureResult.error as FetchBaseQueryError,
          };
        }

        const { result: signatureResultData } =
          signatureResult.data as APIResponse<ImageUploadSignatureResponse>;

        const body = new FormData();
        body.append("file", file);
        (
          Object.keys(
            signatureResultData
          ) as (keyof ImageUploadSignatureResponse)[]
        ).forEach((key) => {
          body.append(key, signatureResultData[key]);
        });

        const uploadResult = await fetchWithBQ({
          url: `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
          method: "post",
          body,
          credentials: "omit",
        });

        if (uploadResult.error) {
          return { error: uploadResult.error as FetchBaseQueryError };
        }

        return { data: uploadResult.data as ImageUploadResponse };
      },
    }),
  }),
});
