import {
  Configuration,
  CurrentUserAPIsApi,
} from "@givery-technology/track-account-frontend-sdk/generated-sdk";

import { getURLWithVersion } from "./UrlService";

const configuration = new Configuration({
  basePath: getURLWithVersion(""),
});

export const api = new CurrentUserAPIsApi(configuration);
