export const rollbarConfig = {
  accessToken: import.meta.env.APP_ROLLBAR_ACCESS_TOKEN,
  environment: import.meta.env.APP_ENVIRONMENT,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: import.meta.env.APP_ENVIRONMENT,
    client: {
      javascript: {
        source_map_enabled: true,
        code_version: import.meta.env.APP_VERSION,
        guess_uncaught_frames: true,
      },
    },
  },
};
