import classnames from "classnames";
import React, { DetailedHTMLProps, InputHTMLAttributes } from "react";
import { FieldError, Path, UseFormRegister } from "react-hook-form";

import { ValidationMessage } from "@/shared/components";

export type RadioProps<T> = {
  label?: Path<T>;
  register?: UseFormRegister<T>;
  error?: FieldError;
  children?: React.ReactNode;
  labelClassName?: string;
  radioClassName?: string;
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export function Radio<T>(props: RadioProps<T>) {
  const {
    id,
    disabled,
    readOnly,
    error,
    label,
    register,
    children,
    className,
    labelClassName,
    radioClassName,
    ...rest
  } = props;

  const rootStyle = classnames("inline-flex flex-col gap-2", {
    [`${className}`]: Boolean(className),
  });

  const labelStyle = classnames("inline-flex gap-2", {
    "cursor-not-allowed opacity-50": disabled || readOnly,
    "cursor-pointer": !(disabled || readOnly),
    [`${labelClassName}`]: Boolean(labelClassName),
  });

  const inputStyle = classnames("form-radio my-1", {
    "border-red-500": Boolean(error),
    "cursor-not-allowed": disabled || readOnly,
    [`${radioClassName}`]: Boolean(radioClassName),
  });

  return (
    <div className={rootStyle}>
      <label className={labelStyle} htmlFor={id}>
        <input
          type="radio"
          className={inputStyle}
          disabled={disabled}
          readOnly={readOnly}
          {...(label && register && register(label))}
          {...rest}
        />
        <div>{children}</div>
      </label>
      {error && <ValidationMessage error={error} />}
    </div>
  );
}
