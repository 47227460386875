import classnames from "classnames";
import { DetailedHTMLProps, FormHTMLAttributes } from "react";

export type FormProps = {
  children: React.ReactNode;
} & DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>;

export function Form(props: FormProps) {
  const { children, className, ...rest } = props;

  const rootStyle = classnames("flex flex-col gap-8 max-w-lg", {
    [`${className}`]: Boolean(className),
  });

  return (
    <form className={rootStyle} {...rest}>
      {children}
    </form>
  );
}
