import classnames from "classnames";

export type TagProps = {
  children: React.ReactNode;
  className?: string;
};

export function Tag(props: TagProps) {
  const { children, className } = props;
  const rootStyle = classnames(
    "px-2 py-1 text-xs font-normal bg-gray-100 rounded",
    {
      [`${className}`]: Boolean(className),
    }
  );

  return <span className={rootStyle}>{children}</span>;
}
