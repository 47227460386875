import { Provider, ErrorBoundary } from "@rollbar/react";

import { AppRoute, rollbarConfig } from "@/app";
import { Toast } from "@/shared/components";

export function App() {
  return (
    <Provider config={rollbarConfig}>
      <ErrorBoundary>
        <div className="flex h-screen w-screen bg-slate-50 overflow-hidden">
          <AppRoute />
          <Toast />
        </div>
      </ErrorBoundary>
    </Provider>
  );
}
