import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Routes, Route, Navigate } from "react-router-dom";

import {
  AccountProfile,
  AccountSecurity,
  AccountEmail,
  AccountEmailComplete,
  AccountSetting,
  AccountOrganizations,
  AccountContainer,
  SignIn,
  AuthContainer,
  NotFound,
  AccessDenied,
  Error,
  NotSupported,
} from "@/features";
import { Button } from "@/shared/components";
import { useUser } from "@/shared/hooks";
import { User } from "@/shared/models";
import { BrowserService } from "@/shared/services";

export function AppRoute() {
  const { t } = useTranslation();

  const [currentUser, setCurrentUser] = useState<User | undefined>(undefined);

  const { data: user, isLoading } = useUser();

  useEffect(() => {
    if (user !== undefined) {
      if (currentUser === undefined) {
        setCurrentUser(user.user);
      } else if (currentUser.userId !== user.user.userId) {
        window.location.reload();
      }
    }
  }, [user, currentUser, setCurrentUser]);

  if (!BrowserService.isValidBrowser) {
    return (
      <Routes>
        <Route path="/" element={<AuthContainer />}>
          <Route path="*" element={<NotSupported />} />
        </Route>
      </Routes>
    );
  }

  if (isLoading) {
    return (
      <Routes>
        <Route path="/" element={<AuthContainer />}>
          <Route
            path="*"
            element={
              <div className="container flex flex-col items-center gap-4 p-8">
                <p>Loading...</p>
                <div>
                  <Button color="link" onClick={() => window.location.reload()}>
                    {t("message.loading")}
                  </Button>
                </div>
              </div>
            }
          />
        </Route>
      </Routes>
    );
  }

  if (user === undefined) {
    return (
      <Routes>
        <Route path="/" element={<AuthContainer />}>
          <Route index element={<Navigate to="/auth/signin" replace />} />
          <Route path="auth/signin" element={<SignIn />} />
          <Route path="error">
            <Route index element={<Error />} />
            <Route path="access-denied" element={<AccessDenied />} />
            <Route path="notfound" element={<NotFound />} />
          </Route>
          <Route path="*" element={<Navigate to="/auth/signin" replace />} />
        </Route>
      </Routes>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<AccountContainer />}>
        <Route index element={<Navigate to="/user/profile" replace />} />
        <Route path="/user">
          <Route index element={<Navigate to="/user/profile" replace />} />
          <Route path="profile" element={<AccountProfile />} />
          <Route path="security" element={<AccountSecurity />} />
          <Route path="email" element={<AccountEmail />} />
          <Route path="emailupdate/:token" element={<AccountEmailComplete />} />
          <Route path="settings" element={<AccountSetting />} />
          <Route path="organizations" element={<AccountOrganizations />} />
        </Route>
        <Route path="error">
          <Route index element={<Error />} />
          <Route path="access-denied" element={<AccessDenied />} />
          <Route path="notfound" element={<NotFound />} />
        </Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Route>
    </Routes>
  );
}
