import classnames from "classnames";
import { ToastContainer as RTToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "./Toast.css";

const toastContextClass = {
  success: "bg-teal-700",
  error: "bg-rose-700",
  info: "bg-sky-700",
  warning: "bg-amber-700",
  default: "bg-slate-700",
};

export function Toast() {
  return (
    <RTToastContainer
      className="flex flex-col gap-2"
      toastClassName={(context) =>
        classnames(
          "flex p-2 min-h-10 rounded justify-between overflow-hidden cursor-pointer",
          { [`${toastContextClass[context?.type ?? "default"]}`]: true }
        )
      }
      bodyClassName={() => "flex gap-2 text-sm font-white p-2"}
      closeButton={false}
      hideProgressBar
      autoClose={5000}
      pauseOnHover
      pauseOnFocusLoss={false}
      draggable={false}
      limit={5}
      role="alert"
    />
  );
}
