import { createPortal } from "react-dom";

import { Icon, IconTypeSolid } from "@/shared/components";

export type ModalProps = {
  modalHeader?: React.ReactNode;
  modalBody?: React.ReactNode;
  modalFooter?: React.ReactNode;
  onClose: () => void;
  ariaLabel?: string;
};

export function Modal(props: ModalProps) {
  const { ariaLabel, modalHeader, modalBody, modalFooter, onClose } = props;

  return createPortal(
    <div
      className="relative z-10"
      aria-label={ariaLabel}
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-black bg-opacity-50 transition-opacity" />
      <div className="fixed inset-0 z-10 overflow-y-auto">
        <div className="flex flex-col min-h-full items-center justify-center p-4 text-center">
          <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all">
            <div className="flex justify-between gap-4 bg-white border-b p-4">
              <div className="flex-grow">{modalHeader}</div>
              <div>
                <button aria-label="Close" onClick={onClose}>
                  <Icon
                    icon={IconTypeSolid.faCircleXmark}
                    className="text-slate-300 hover:text-slate-400 transition-colors"
                  />
                </button>
              </div>
            </div>
            <div className="flex flex-col bg-white">{modalBody}</div>
            <div className="flex bg-gray-50 p-4">{modalFooter}</div>
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
}
