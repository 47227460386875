import classnames from "classnames";
import { DetailedHTMLProps, HTMLAttributes } from "react";

export type FormGroupProps = {
  children: React.ReactNode;
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export function FormGroup(props: FormGroupProps) {
  const { children, className } = props;

  const rootStyle = classnames("flex flex-col gap-2", {
    [`${className}`]: Boolean(className),
  });

  return <div className={rootStyle}>{children}</div>;
}
