import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import * as yup from "yup";

import {
  Label,
  Page,
  PageTitle,
  Form,
  FormGroup,
  Button,
  Radio,
} from "@/shared/components";
import { useUpdateUser, useUser } from "@/shared/hooks";
import { SpokenLang } from "@/shared/models";
import { ErrorService } from "@/shared/services";

type AccountSettingForm = {
  lang: string;
};

export function AccountSetting() {
  const { t } = useTranslation();

  const schema = useMemo(
    () =>
      yup.object({
        lang: yup.string().required(),
        mfaRequired: yup.bool(),
      }),
    [yup]
  );

  const { data: user } = useUser();

  const { mutateAsync: updateUser, isPending: submitting } = useUpdateUser();

  const { register, handleSubmit, setValue } = useForm<AccountSettingForm>({
    mode: "all",
    resolver: yupResolver(schema),
  });

  const onSubmit: SubmitHandler<AccountSettingForm> = async (data, e) => {
    e?.preventDefault();
    if (user === undefined) {
      return;
    }

    try {
      const { name, picture } = user.user;

      const lang = data.lang as SpokenLang;

      await updateUser({ lang, name, picture });
      toast.success(t("message.accountUpdated"));
    } catch (error) {
      toast.error(ErrorService.parseAPIErrorMessage(error));
    }
  };

  useEffect(() => {
    if (user) {
      setValue("lang", user.user.userMetadata.lang);
    }
  }, [user, setValue]);

  return (
    <Page>
      <PageTitle>{t("accountSetting.title")}</PageTitle>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Label>{t("accountSetting.language")}</Label>
          <Radio label="lang" register={register} value="ja">
            日本語
          </Radio>
          <Radio label="lang" register={register} value="en">
            English
          </Radio>
        </FormGroup>
        <FormGroup>
          <div>
            <Button type="submit" color="primary" disabled={submitting}>
              {t("accountSetting.save")}
            </Button>
          </div>
        </FormGroup>
      </Form>
    </Page>
  );
}
