import { useEffect } from "react";
import {
  NavLink,
  Outlet,
  useSearchParams,
  useNavigate,
} from "react-router-dom";

import { Footer } from "@/shared/components";

export function AuthContainer() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const code = searchParams.get("code");

  useEffect(() => {
    if (code) {
      navigate(`/auth/signin?code=${code}`, { replace: true });
    }
  }, [code]);

  return (
    <div className="flex flex-col flex-grow">
      <header className="sticky top-0 flex item-center h-16 p-4 bg-white shadow">
        <div>
          <NavLink to="/auth/signin">
            <img
              src="/assets/images/track_logo.svg"
              alt="logo"
              className="h-8"
            />
          </NavLink>
        </div>
      </header>
      <main className="flex flex-col flex-grow">
        <Outlet />
      </main>
      <Footer className="z-10" />
    </div>
  );
}
