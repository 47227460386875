import classnames from "classnames";
import { DetailedHTMLProps, HTMLAttributes, useEffect, useState } from "react";

import DefaultAvatarImage from "./default_avatar.png";
import DefaultOrgImage from "./default_org.png";

export { DefaultAvatarImage, DefaultOrgImage };

export type AvatarProps = {
  size?: "small" | "normal" | "large" | "xLarge";
  alt?: string;
  imageUrl?: string;
  defaultImage?: string;
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export function Avatar(props: AvatarProps) {
  const {
    size = "normal",
    imageUrl,
    defaultImage = DefaultAvatarImage,
    alt,
    className,
  } = props;

  const [error, setError] = useState(false);

  const rootStyle = classnames("flex overflow-hidden", {
    [`${className}`]: Boolean(className),
  });

  const imageStyle = classnames("inline-block rounded", {
    "h-8 w-8": size === "small",
    "h-10 w-10": size === "normal",
    "h-16 w-16": size === "large",
    "h-32 w-32": size === "xLarge",
  });

  useEffect(() => {
    if (!imageUrl) {
      setError(true);
    }
  }, [setError, imageUrl]);

  return (
    <div className={rootStyle}>
      <img
        className={imageStyle}
        src={error ? defaultImage : imageUrl}
        alt={alt}
        onError={() => setError(true)}
      />
    </div>
  );
}
