import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import { Button } from "@/shared/components";

export function NotFound() {
  const { t } = useTranslation();
  const navigation = useNavigate();

  return (
    <div className="flex gap-8 p-8">
      <div className="border-r pr-8">
        <h1 className="font-extrabold text-8xl text-slate-400">404</h1>
      </div>
      <div className="flex flex-col gap-16">
        <div className="flex flex-col gap-4">
          <h2 className="font-bold text-2xl text-slate-700">
            {t("notFound.title")}
          </h2>
        </div>
        <div>
          <Button color="secondary" onClick={() => navigation("/")}>
            {t("notFound.top")}
          </Button>
        </div>
      </div>
    </div>
  );
}
