import classnames from "classnames";
import { DetailedHTMLProps, HTMLAttributes, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import { FieldError } from "react-hook-form";
import { useTranslation } from "react-i18next";

import {
  AvatarProps,
  Avatar,
  Button,
  Icon,
  IconTypeSolid,
  ValidationMessage,
} from "@/shared/components";

const MAX_UPLOAD_FILE_SIZE = 10000000; // 10MB
const ACCEPT_FILE_TYPES = { "image/*": [] };

export type ImageUploadProps = {
  id?: string;
  alt?: string;
  onUploaded?: (files: File[]) => void;
  disabled?: boolean;
  accept?: {};
  maxSize?: number;
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> &
  AvatarProps;

export function ImageUpload(props: ImageUploadProps) {
  const {
    id,
    alt,
    size = "normal",
    imageUrl,
    disabled,
    accept = ACCEPT_FILE_TYPES,
    maxSize = MAX_UPLOAD_FILE_SIZE,
    onUploaded,
    className,
  } = props;

  const { t } = useTranslation();

  const [error, setError] = useState<FieldError | undefined>();

  const onDropRejected = (fileRejections: FileRejection[]) => {
    fileRejections.forEach((rejection) => {
      if (!error) {
        rejection.errors.forEach((e) => {
          if (e.code === "file-too-large") {
            setError({ type: e.code, message: t("imageUpload.tooLarge") });
          } else if (e.code === "file-invalid-type") {
            setError({ type: e.code, message: t("imageUpload.unsupported") });
          }
        });
      }
    });
  };

  const onDropCompleted = (acceptedFiles: File[]) => {
    if (typeof onUploaded === "function") {
      onUploaded(acceptedFiles);
    }
    setError(undefined);
  };

  const { getInputProps, getRootProps, open } = useDropzone({
    accept,
    multiple: false,
    disabled,
    maxSize,
    onDropRejected,
    onDrop: onDropCompleted,
  });

  const rootStyle = classnames("inline-flex flex-col gap-2", {
    [`${className}`]: Boolean(className),
  });

  const avatarStyle = classnames("border rounded", {
    "cursor-not-allowed opacity-50": disabled,
    "cursor-pointer": !disabled,
  });

  return (
    <div className={rootStyle}>
      <div className="self-center" {...getRootProps()}>
        <Avatar
          size={size}
          imageUrl={imageUrl}
          className={avatarStyle}
          alt={alt}
        />
      </div>
      <div className="self-center">
        <Button onClick={open} size="small" disabled={disabled}>
          <Icon icon={IconTypeSolid.faCameraAlt} />
          {t("imageUpload.chooseImage")}
        </Button>
      </div>
      <input id={id} {...getInputProps()} />
      {error && <ValidationMessage error={error} />}
    </div>
  );
}
