import * as IconTypeRegular from "@fortawesome/free-regular-svg-icons";
import * as IconTypeSolid from "@fortawesome/free-solid-svg-icons";
import {
  FontAwesomeIconProps,
  FontAwesomeIcon,
} from "@fortawesome/react-fontawesome";
import classnames from "classnames";

export { IconTypeSolid, IconTypeRegular };

export type IconProps = {} & FontAwesomeIconProps;

export function Icon(props: IconProps) {
  const { className, ...rest } = props;

  const rootStyle = classnames({
    [`${className}`]: Boolean(className),
  });

  return <FontAwesomeIcon {...rest} className={rootStyle} />;
}
