const API_ERRORS = new Map<string, string>();
API_ERRORS.set("1", "message.invalidOrganization");
API_ERRORS.set("10", "message.authenticationFailed");
API_ERRORS.set("20", "message.invalidEmailSSO");
API_ERRORS.set("30", "message.notInvited");
API_ERRORS.set("90", "message.authenticationError");

type APIErrorResponse = {
  status: number;
  data: {
    code: number;
    messages: string[];
  };
};

/**
 * get error message key from the error code
 * @param code
 * @returns
 */
export function extractAPIAuthError(code: string | null): string | undefined {
  if (code === "" || code === null) {
    return undefined;
  }
  return API_ERRORS.get(code) ?? "message.error";
}

/**
 * parse message string from unknown TAS error object
 * @param error
 * @returns
 */
export function parseAPIErrorMessage(error: unknown) {
  if (error === undefined || error === null) {
    return "";
  }

  if (typeof error === "string") {
    return error;
  }

  const maybeTASError = error as Record<keyof APIErrorResponse, unknown>;

  if (typeof maybeTASError.data === "object" && maybeTASError.data !== null) {
    const maybeTASErrorObject = maybeTASError.data as Record<
      keyof APIErrorResponse["data"],
      unknown
    >;
    return Array.isArray(maybeTASErrorObject.messages)
      ? maybeTASErrorObject.messages.join(" ")
      : JSON.stringify(maybeTASErrorObject);
  }

  return JSON.stringify(error);
}
