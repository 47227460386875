import classnames from "classnames";
import { DetailedHTMLProps, LabelHTMLAttributes } from "react";

export type LabelProps = {
  children: React.ReactNode;
} & DetailedHTMLProps<LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>;

export function Label(props: LabelProps) {
  const { htmlFor, children, className, ...rest } = props;
  const rootStyle = classnames("inline-block text-sm font-bold text-gray-900", {
    [`${className}`]: Boolean(className),
  });
  return (
    <label htmlFor={htmlFor} className={rootStyle} {...rest}>
      {children}
    </label>
  );
}
