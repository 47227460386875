import { useTranslation } from "react-i18next";

import {
  Avatar,
  Page,
  PageTitle,
  DefaultOrgImage,
  IconTypeSolid,
  Icon,
  Tag,
} from "@/shared/components";
import { useUser } from "@/shared/hooks";
import { extendOrgsWithServiceLinks } from "@/shared/services/UserService";

export function AccountOrganizations() {
  const { t } = useTranslation();

  const { data: user } = useUser();

  const orgsWithLinks = user
    ? extendOrgsWithServiceLinks(user, window.location.hostname)
    : [];

  return (
    <Page>
      <PageTitle>{t("accountOrganizations.title")}</PageTitle>
      <p>{t("accountOrganizations.description")}</p>
      <ul className="flex flex-col rounded bg-white max-w-xl shadow">
        {orgsWithLinks
          .filter((org) => org.metadata.enabled)
          .map((org) => (
            <li
              key={org.id}
              className="flex justify-between items-center gap-4 border-b p-4"
            >
              <div className="flex items-stretch gap-4 min-w-0">
                <div className="shrink-0 self-center">
                  <Avatar
                    imageUrl={org.logoUrl}
                    defaultImage={DefaultOrgImage}
                  />
                </div>
                <div className="flex flex-col justify-between gap-2">
                  <div className="break-words min-w-0 text-sm">
                    {org.displayName}
                  </div>
                  <div className="flex flex-row gap-4">
                    {org.serviceLinks.map((link) => (
                      <div
                        key={link.displayName}
                        className="flex flex-row gap-1 items-center font-medium text-sm"
                      >
                        <a
                          className="leading-4 text-sky-800 underline hover:text-sky-900 hover:no-underline"
                          href={link.url}
                          target="_blank"
                          rel="noreferrer noopener"
                        >
                          {link.displayName}
                        </a>
                        <Icon
                          className="text-xs text-sky-800"
                          icon={IconTypeSolid.faExternalLink}
                          size="1x"
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="shrink-0">
                <Tag>
                  {t(
                    org.metadata.ssoEnabled
                      ? "accountOrganizations.sso"
                      : "accountOrganizations.password"
                  )}
                </Tag>
              </div>
            </li>
          ))}
      </ul>
    </Page>
  );
}
