import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  ApplyEmailChangeInput,
  UpdateUserProfileInput,
  RequestEmailChangeInput,
} from "@/shared/models";
import { api } from "@/shared/services/APIService";

export const useUser = () => {
  const { i18n } = useTranslation();

  const { data, ...rest } = useQuery({
    queryKey: ["user"],
    queryFn: () => api.getApiUser(),
    refetchOnMount: true,
    refetchOnReconnect: true,
    staleTime: 1000 * 60,
  });

  useEffect(() => {
    if (data?.data.result.user.userMetadata) {
      const { lang } = data.data.result.user.userMetadata;
      if (i18n.language !== lang) {
        i18n.changeLanguage(lang);
      }
    }
  }, [i18n.language, data]);

  return { data: data?.data.result, ...rest };
};

export const useUserMfa = () => {
  const { data, ...rest } = useQuery({
    queryKey: ["userMfa"],
    queryFn: () => api.getApiUserMfa(),
    refetchOnMount: true,
    refetchOnReconnect: true,
    staleTime: 1000 * 60,
  });

  return { data: data?.data.result, ...rest };
};

export const useUpdateUser = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (args: UpdateUserProfileInput) => api.putApiUser(args),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["user"],
      });
    },
  });
};

export const useUpdateUserEmail = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (args: RequestEmailChangeInput) => api.postApiUserEmail(args),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["user"],
      });
    },
  });
};

export const useUpdateUserEmailComplete = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (args: ApplyEmailChangeInput) => api.putApiUserEmail(args),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["user"],
      });
    },
  });
};

export const useUpdateUserPassword = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => api.postApiUserPassword(),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["user"],
      });
    },
  });
};

export const useDeleteUserMfa = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: () => api.deleteApiUserMfa(),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["userMfa"],
      });
    },
  });
};
