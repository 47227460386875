import classnames from "classnames";
import { DetailedHTMLProps, HTMLAttributes } from "react";

export type PageTitleProps = {
  children: React.ReactNode;
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export function PageTitle(props: PageTitleProps) {
  const { children, className } = props;

  const rootStyle = classnames("text-2xl font-bold", {
    [`${className}`]: Boolean(className),
  });

  return <h2 className={rootStyle}>{children}</h2>;
}
