import { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import {
  Page,
  PageTitle,
  Button,
  Icon,
  IconTypeSolid,
  Avatar,
  Modal,
  DefaultOrgImage,
} from "@/shared/components";
import {
  useGetUserMFAQuery,
  useUpdatePasswordMutation,
  useResetUserMFAMutation,
  useGetUserQuery,
} from "@/shared/hooks";
import { ErrorService, UserService } from "@/shared/services";

export function AccountSecurity() {
  const { t } = useTranslation();

  const [isOpen, setOpen] = useState(false);

  const { data: userMFA } = useGetUserMFAQuery(undefined, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });

  const { data: user } = useGetUserQuery();

  const [updatePassword, { isLoading: submitting }] =
    useUpdatePasswordMutation();

  const [resetUserMFA] = useResetUserMFAMutation();

  const onClick = (e: React.MouseEvent) => {
    e?.preventDefault();
    updatePassword()
      .unwrap()
      .then(() => {
        toast.success(t("message.passwordUpdated"));
      })
      .catch((error) => {
        toast.error(ErrorService.parseAPIErrorMessage(error));
      });
  };

  const onResetUserMFA = (e: React.MouseEvent) => {
    e?.preventDefault();
    resetUserMFA()
      .unwrap()
      .then(() => {
        toast.success(t("message.userMFAReset"));
        setOpen(false);
      })
      .catch((error) => {
        toast.error(ErrorService.parseAPIErrorMessage(error));
      });
  };

  return (
    <Page>
      <PageTitle>{t("accountSecurity.title")}</PageTitle>
      {!UserService.onlySSOIntegration(user) && (
        <section className="flex flex-col pb-8 gap-8 border-b">
          <h3 className="text-xl font-bold">
            {t("accountSecurity.passwordChange.title")}
          </h3>
          {UserService.hasSSOIntegration(user) && (
            <div className="bg-white border border-yellow-500 p-4 rounded flex items-center gap-4">
              <Icon
                icon={IconTypeSolid.faWarning}
                size="2x"
                className="text-yellow-500"
              />
              {t("accountSecurity.passwordChange.ssoIntegrated")}
            </div>
          )}
          <p>{t("accountSecurity.passwordChange.description")}</p>
          <div>
            <Button
              type="button"
              color="primary"
              disabled={submitting}
              onClick={onClick}
            >
              <Icon icon={IconTypeSolid.faEnvelope} />
              {t("accountSecurity.buttons.passwordChange")}
            </Button>
          </div>
        </section>
      )}
      <section className="flex flex-col gap-8">
        <h3 className="text-xl font-bold">
          {t("accountSecurity.userMFA.title")}
        </h3>
        {userMFA?.enabled ? (
          <>
            <p>{t("accountSecurity.userMFA.description")}</p>
            <div>
              <ul className="flex flex-col gap-4 py-2 px-4 rounded bg-white max-w-lg">
                {user?.userOrgs
                  .filter((org) => org.metadata.mfaRequired)
                  .map((org) => (
                    <li
                      key={org.id}
                      className="flex items-center gap-4 min-w-0"
                    >
                      <div className="self-start shrink-0">
                        <Avatar
                          imageUrl={org.logoUrl}
                          defaultImage={DefaultOrgImage}
                        />
                      </div>
                      <div className="break-words min-w-0">
                        {org.displayName}
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
            <div>
              <Button
                type="button"
                color="danger"
                disabled={submitting}
                onClick={() => setOpen(true)}
              >
                <Icon icon={IconTypeSolid.faTrashAlt} />
                {t("accountSecurity.buttons.reset")}
              </Button>
            </div>
          </>
        ) : (
          <p>{t("accountSecurity.userMFA.notEnrolled")}</p>
        )}
      </section>
      {isOpen && (
        <Modal
          ariaLabel={t("accountSecurity.userMFA.deleteConfirm.title")}
          modalHeader={t("accountSecurity.userMFA.deleteConfirm.title")}
          modalBody={
            <p className="p-4">
              {t("accountSecurity.userMFA.deleteConfirm.description")}
            </p>
          }
          modalFooter={
            <div className="flex gap-4">
              <Button onClick={() => setOpen(false)}>
                {t("accountSecurity.buttons.cancel")}
              </Button>
              <Button color="danger" onClick={onResetUserMFA}>
                {t("accountSecurity.buttons.reset")}
              </Button>
            </div>
          }
          onClose={() => setOpen(false)}
        />
      )}
    </Page>
  );
}
