import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

import { Icon, IconTypeRegular } from "@/shared/components";

import { MenuTitle, MenuItem } from "./partials";

export function Menu() {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-stretch shrink-0 bg-slate-800 w-64 border-r">
      <div className="flex items-center h-16 px-4 bg-slate-900">
        <NavLink to="/">
          <img src="/assets/images/logo.svg" alt="logo" className="h-8" />
        </NavLink>
      </div>
      <ul className="py-4">
        <li>
          <MenuTitle>
            <Icon icon={IconTypeRegular.faUser} />
            {t("menu.account")}
          </MenuTitle>
          <ul className="px-2">
            <MenuItem to="/user/profile">{t("menu.accountProfile")}</MenuItem>
            <MenuItem to="/user/security">{t("menu.accountSecurity")}</MenuItem>
            <MenuItem to="/user/organizations">
              {t("menu.accountOrganizations")}
            </MenuItem>
            <MenuItem to="/user/email">{t("menu.accountEmail")}</MenuItem>
            <MenuItem to="/user/settings">{t("menu.accountSetting")}</MenuItem>
          </ul>
        </li>
      </ul>
    </div>
  );
}
