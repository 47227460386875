import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";

import type { RootState, AppDispatch } from "@/shared/store";
import { apiSlice } from "@/shared/store/APISlice";

export const {
  useGetUserQuery,
  useUpdateUserMutation,
  useUploadImageMutation,
  useUpdatePasswordMutation,
  useUpdateEmailMutation,
  useUpdateEmailCompleteMutation,
  useGetUserMFAQuery,
  useResetUserMFAMutation,
} = apiSlice;

export const useAppDispatch = () => useDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export * from "./useValidation";
