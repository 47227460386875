import { StrictMode, Suspense } from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";

import { App } from "@/app";
import "@/shared/services/I18nService";
import { store } from "@/shared/store";

import "./main.css";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <StrictMode>
    <BrowserRouter>
      <Provider store={store}>
        <Suspense fallback="Loading...">
          <App />
        </Suspense>
      </Provider>
    </BrowserRouter>
  </StrictMode>
);
