import classnames from "classnames";
import { DetailedHTMLProps, InputHTMLAttributes } from "react";
import { FieldError, Path, UseFormRegister } from "react-hook-form";

import { ValidationMessage } from "@/shared/components";

export type InputProps<T> = {
  label?: Path<T>;
  register?: UseFormRegister<T>;
  error?: FieldError;
  inputClassName?: string;
} & DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export function Input<T>(props: InputProps<T>) {
  const { error, label, register, className, inputClassName, ...rest } = props;

  const rootStyle = classnames("flex flex-col flex-grow gap-2", {
    [`${className}`]: Boolean(className),
  });

  const inputStyle = classnames("form-input rounded", {
    "border-rose-500": Boolean(error),
    [`${inputClassName}`]: Boolean(inputClassName),
  });

  return (
    <div className={rootStyle}>
      <input
        className={inputStyle}
        {...(register && label && register(label))}
        {...rest}
      />
      {error && <ValidationMessage error={error} />}
    </div>
  );
}
