import { stringifyUrl } from "query-string";
import { MouseEvent, KeyboardEvent, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { usePopper, Modifier } from "react-popper";

import { Avatar } from "@/shared/components";
import { useUser } from "@/shared/hooks/useUserQuery";
import { UrlService } from "@/shared/services";

const { getURLWithVersion } = UrlService;

export function Header() {
  const [isOpen, setIsOpen] = useState(false);

  const { data: user } = useUser();

  const { t } = useTranslation();

  const modifiers = useMemo<Modifier<"offset" | "arrow" | "preventOverflow">[]>(
    () => [
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
      {
        name: "preventOverflow",
        options: {
          // prevent wrong position calculation when the reference element is placed on the edge of the modal content.
          mainAxis: false,
        },
      },
    ],
    []
  );

  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );

  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: "bottom-end",
    modifiers,
  });

  const onOpen = (e: MouseEvent<HTMLDivElement> | KeyboardEvent) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const onSignOut = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    window.location.href = stringifyUrl({
      url: getURLWithVersion("/login/session/logout"),
      query: {
        ...(import.meta.env.APP_AUTH_REDIRECT_ORIGIN_URL && {
          origin: import.meta.env.APP_AUTH_REDIRECT_ORIGIN_URL,
        }),
      },
    });
  };

  useEffect(() => {
    if (isOpen) {
      document.body.addEventListener("click", () => setIsOpen(false), {
        once: true,
      });
    }
  }, [isOpen, setIsOpen]);

  return (
    <header className="sticky top-0 flex justify-end item-center h-16 p-4 bg-white border-b shadow">
      <div className="flex items-center gap-4">
        <div
          role="button"
          ref={setReferenceElement}
          className="flex items-center gap-2 cursor-pointer"
          tabIndex={0}
          onClick={onOpen}
          onKeyDown={onOpen}
        >
          <Avatar imageUrl={user?.user.picture} size="small" />
        </div>
        {isOpen && (
          <div
            ref={setPopperElement}
            style={styles.popper}
            className="rounded border shadow bg-white hover:bg-slate-50"
            {...attributes.popper}
          >
            <button onClick={onSignOut} className="py-4 px-6">
              {t("header.signOut")}
            </button>
          </div>
        )}
      </div>
    </header>
  );
}
