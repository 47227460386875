import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import { ImageUploadRequestPayload } from "@/shared/models";
import { api } from "@/shared/services/APIService";

export const useUploadImage = () =>
  useMutation({
    mutationFn: async ({
      file,
      width,
      height,
      tags,
      transformation,
    }: ImageUploadRequestPayload) => {
      const configResult = await api.getApiImagesConfig();

      const { cloud_name: cloudName } = configResult.data.result;

      const signatureResult = await api.getApiImagesSignature(
        Number(width),
        Number(height),
        transformation,
        tags
      );

      const signatureResultData = signatureResult.data.result;
      const body = new FormData();
      body.append("file", file);

      (
        Object.keys(signatureResultData) as (keyof ImageUploadRequestPayload)[]
      ).forEach((key) => {
        body.append(key, signatureResultData[key]);
      });

      return axios.post(
        `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
        body,
        { withCredentials: false }
      );
    },
  });
