import { useTranslation } from "react-i18next";

export function NotSupported() {
  const { t } = useTranslation();

  return (
    <div className="flex gap-8 p-8">
      <div className="border-r pr-8">
        <h1 className="font-extrabold text-8xl text-slate-400">405</h1>
      </div>
      <div className="flex flex-col gap-16">
        <div className="flex flex-col gap-4">
          <h2 className="font-bold text-2xl text-slate-700">
            {t("notSupported.title")}
          </h2>
          <p className="text-slate-700">{t("notSupported.subtitle")}</p>
        </div>
      </div>
    </div>
  );
}
