import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo } from "react";
import { useForm, SubmitHandler, Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import {
  Label,
  Input,
  ImageUpload,
  Page,
  PageTitle,
  Form,
  FormGroup,
  Button,
} from "@/shared/components";
import {
  useValidation,
  useUpdateUser,
  useUser,
  useUploadImage,
} from "@/shared/hooks";
import { ErrorService } from "@/shared/services";

type AccountProfileForm = {
  name: string;
  picture: string;
};

export function AccountProfile() {
  const { yup } = useValidation();
  const schema = useMemo(
    () =>
      yup.object({
        name: yup.string().trim().required().max(40),
      }),
    [yup]
  );

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
  } = useForm<AccountProfileForm>({
    mode: "all",
    resolver: yupResolver(schema),
  });

  const { data: user } = useUser();

  const { t } = useTranslation();

  const { mutateAsync: uploadImage, isPending: uploading } = useUploadImage();

  const { mutateAsync: updateUser, isPending: submitting } = useUpdateUser();

  const onSubmit: SubmitHandler<AccountProfileForm> = async (data, e) => {
    e?.preventDefault();

    if (user === undefined) {
      return;
    }

    try {
      const {
        user: {
          userMetadata: { lang },
        },
      } = user;
      await updateUser({ ...data, lang });
      toast.success(t("message.accountUpdated"));
    } catch (error) {
      toast.error(ErrorService.parseAPIErrorMessage(error));
    }
  };

  const onUploaded = async (files: File[]) => {
    try {
      const result = await uploadImage({
        file: files[0],
        width: "160",
        height: "160",
        tags: "user",
        transformation: "g_face",
      });

      setValue("picture", result.data.secure_url, {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
    } catch (error) {
      toast.error(ErrorService.parseAPIErrorMessage(error));
    }
  };

  useEffect(() => {
    if (user) {
      setValue("name", user.user.name);
      setValue("picture", user.user.picture ?? "");
    }
  }, [setValue, user]);

  return (
    <Page>
      <PageTitle>{t("accountProfile.title")}</PageTitle>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Label>{t("accountProfile.email")}</Label>
          <p>{user?.user.email}</p>
        </FormGroup>
        <FormGroup>
          <Label htmlFor="name">{t("accountProfile.name")}</Label>
          <Input
            id="name"
            label="name"
            register={register}
            defaultValue={user?.user.name}
            error={errors.name}
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="imageUrl">{t("accountProfile.profileImage")}</Label>
          <div>
            <Controller
              name="picture"
              control={control}
              defaultValue={user?.user.picture}
              render={({ field }) => (
                <ImageUpload
                  id="imageUrl"
                  size="xLarge"
                  imageUrl={field.value}
                  onUploaded={onUploaded}
                  disabled={uploading || submitting}
                  alt={t("accountProfile.profileImage")}
                />
              )}
            />
          </div>
        </FormGroup>
        <FormGroup>
          <div>
            <Button
              type="submit"
              color="primary"
              disabled={uploading || submitting}
            >
              {t("accountProfile.save")}
            </Button>
          </div>
        </FormGroup>
      </Form>
    </Page>
  );
}
