import { createSlice } from "@reduxjs/toolkit";

import { I18nService } from "@/shared/services";
import { apiSlice } from "@/shared/store/APISlice";

type AppState = {
  language: string;
};

export const initialState: AppState = {
  language: I18nService.language,
};

export const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      apiSlice.endpoints.getUser.matchFulfilled,
      (state, action) => {
        if (action.payload) {
          const {
            user: {
              userMetadata: { lang },
            },
          } = action.payload;
          if (state.language !== lang) {
            I18nService.changeLanguage(lang);
            state.language = lang;
          }
        }
      }
    );
  },
});
